<template>
  <ul
    role="list"
    class="
      grid grid-cols-2
      gap-x-4 gap-y-8
      sm:grid-cols-3 sm:gap-x-6
      lg:grid-cols-4
      xl:gap-x-8
      px-4 py-6
    "
    style="overflow: scroll; max-height: calc(100vh - 130px)"
  >
    <div class="image-details" v-if="imageDetails && imageDetails.show" :style="`top: ${imageDetails.y}px; left: ${imageDetails.x}px;`">
      <p><span class="text-black-700 font-semibold">Status: </span>{{ (imageDetails.status.replace('recognition', 'processing')).charAt(0).toUpperCase() + (imageDetails.status.replace('recognition', 'processing')).slice(1).replace("_", " ") }}</p>
    </div>
    <li v-for="image in images" :key="image.id" class="relative">
      <div
        @click="clickPhoto(image)"
        @mouseover="showDetails($event, image)"
        @mouseleave="closeDetails()"
        @mousemove="showDetails($event, image)"
        :id="image.id"
        :class="[
          getStatus(image) !== 'initial' &&
            `img-border-${getBorderColorFromStatus(getStatus(image))}-500`,
          'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden',
        ]"
        style="
          min-width: 225px;
          max-width: 440px;
          max-height: 360px;
          min-height: 190px;
          background-color: rgba(220, 220, 220, 0.7);
        "
      >
        <img
          v-if="image.thumbnail_url"
          :src="image.thumbnail_url"
          alt=""
          class="object-cover pointer-events-none group-hover:opacity-75"
        />
        <button type="button"
          class="
            absolute
            inset-0
            focus:outline-none
          "
        >
          <span class="sr-only">View details for {{ image.filename }}</span>
        </button>
      </div>
      <p
        class="
          mt-2
          block
          text-sm
          font-medium
          text-gray-900
          truncate
          pointer-events-none
        "
      >
        {{ image.filename }}
      </p>
    </li>
  </ul>
</template>
<script>
let closeDetailThrottle = null;
export default {
  props: {
    images: Array,
    allImages: Array,
    session: Object,
    siteId: String,
    siteAssessmentId: String,
    clientSiteIdentifier: String,
    sessionToken: String,
    clickPhoto: Function,
    onScroll: Function,
    selectedAIModels: Array,
    getStatus: Function,
  },
  data() {
    return {
      imageDetails: {
        show: false,
        x: null,
        y: null,
        filename: null,
        status: null,
        id: null,
      
      }
    }
  },
  methods: {
    showDetails(event, image) {
      this.imageDetails = {
        show: true,
        x: event.clientX + 20,
        y: event.clientY + 20,
        filename: image.filename,
        id: image.id,
        status: this.getStatus(image),
      };
    },
    handleCloseDetail() {
      this.imageDetails = {
        show: false,
        x: null,
        y: null,
        filename: null,
        status: null,
        id: null,
      };
    },
    throttleCloseDetail() {
      clearTimeout(closeDetailThrottle);
      closeDetailThrottle = setTimeout(() => {
        this.handleCloseDetail();
      }, 200);
    },
    closeDetails() {
      this.throttleCloseDetail();
    },
    getBorderColorFromStatus(status) {
      if (status === "no_recognition") {
        return "gray";
      }

      if (
        [
          "recognition_pending",
          "recognition_running",
          "training_running",
        ].includes(status)
      ) {
        return "yellow";
      }

      if (status === "recognition_finished") {
        return "light-green";
      }

      if (["validating", "validated"].includes(status)) {
        return "light-orange";
      }

      if (status === "training_pending") {
        return "dark-orange";
      }

      if (status === "training_finished") {
        return "green";
      }

      if (status === "recognition_outdated") {
        return "verde-acizentado";
      }
    },
  },
  mounted() {
    this.$el.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    this.$el.removeEventListener("scroll", this.onScroll);
  }
};
</script>
<style>
.img-border-gray-500 {
  border: 5px solid rgba(128, 128, 128, 1);
  border-radius: 10px;
}

.img-border-yellow-500 {
  border: 5px solid rgb(255, 255, 0);
  border-radius: 10px;
}

.img-border-light-yellow-500 {
  border: 5px solid rgb(255, 255, 102);
  border-radius: 10px;
}

.img-border-light-orange-500 {
  border: 5px solid #ffad86;
  border-radius: 10px;
}

.img-border-dark-orange-500 {
  border: 5px solid #ff7e42;
  border-radius: 10px;
}

.img-border-green-500 {
  border: 5px solid #003d07;
  border-radius: 10px;
}

.img-border-light-green-500 {
  border: 5px solid #90ee90;
  border-radius: 10px;
}

.img-border-verde-acizentado-500 {
  border: 5px solid #6e9573;
  border-radius: 10px;
}

.image-details {
  position: absolute;
  z-index: 9999;
  background: rgba(255,255,255, 0.7);
  padding: 10px;
  overflow: hidden;
}
</style>
