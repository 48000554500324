<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="onClose">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex flex-col h-full bg-white">
                  <div class="flex flex-col overflow-y-scroll bg-white shadow-xl">
                    <div class="p-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-lg font-medium text-gray-900"> Current models </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500" @click="onClose">
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      <li v-for="model in models" :key="model.id">
                        <div class="group relative flex items-center py-6 px-5" :style="{border: selectedAIModel && selectedAIModel.id === model.id ? '3px solid rgb(33, 43, 54)' : 'none'}">
                          <div class="-m-1 block flex-1 p-1">
                            <div class="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
                            <div class="relative flex min-w-0 flex-1 items-center">
                              <div class="ml-4 truncate">
                                <p v-if="model && model.name" @click="handleSelectAIModel(model)" class="cursor-pointer truncate text-bg font-bold text-gray-900">{{ model.name }} <span v-if="'color' in model" :class="['ml-1 w-3.5 h-3.5 mr-4 rounded-full']" aria-hidden="true"  :style="{'backgroundColor': model.color, 'display': 'inline-block'}" /></p>
                                <p v-if="model.latest_version && model.latest_version.version" class="truncate text-sm text-gray-500" style="max-width: 340px" :title="'Version: ' + model.latest_version.version">Version: {{ model.latest_version.version }}</p>
                                <div class="flex items-center justify-between">
                                  <p v-if="model && model.status" class="truncate mt-2 text-sm text-green-600">{{ model.is_trainable ? model.status : 'RUNNING' }}</p>
                                  <p v-if="model.latest_version && model.latest_version.f1_score" class="truncate mt-2 text-sm text-gray">Accuracy: {{ model.latest_version.f1_score * 100.0 }}%</p>
                                </div>

                              </div>
                            </div>
                          </div>
                          <Toggle
                              :enabled="selectedAIModels.includes(model.id)"
                              :changeValue="onChangeToggle"
                              :modelId="model.id"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!--
                  <button
                    @click="openAddAIModal"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-6
                      py-3
                      border border-transparent
                      shadow-sm
                      text-base
                      font-medium
                      rounded-md
                      text-white
                      bg-cci
                      m-10"
                  >
                    <PlayIcon class="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
                    Create AI Model
                  </button>
                  -->
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { ref } from 'vue';
import { XIcon } from '@heroicons/vue/outline';
import Toggle from './Toggle.vue';

export default {
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XIcon,
        Toggle,
    },
    props: {
        onClose: Function,
        session: Object,
        selectedAIModels: Array,
        aiModels: Array,
        addAIModel: Function,
        removeAIModel: Function,
        aiModelPhotos: Array,
        selectedAIModel: Object,
        handleSelectAIModel: Function,
        openAddAIModal: Function,
    },
    methods: {
        onChangeToggle(id){
            if (this.selectedAIModels.includes(id)) {
              this.removeAIModel(id);
            } else {
              this.addAIModel(id);
            }
        }
    },
    setup() {
        const open = ref(true);
        
        return {
          open,
        };
    },
    data() {
      return {
        models: [],
      }
    },
    mounted() {
      this.models = this.aiModels;
    },
}

</script>