<template>
  <div>
    <div style="height: 130px; background-color: white">
      <crossbar-connection-header
        :connected="connected"
        :siteAssessmentStatus="siteAssessmentStatus"
        :onClickCurrentModels="onClickCurrentModels"
        :totalModelsCount="aiModels.length"
      ></crossbar-connection-header>

      <ul class="flex border-b" v-if="!shouldImportSite" style="height: 90px">
        <li
          v-for="tab in tabs"
          :key="tab"
          :class="tab === activeTab ? '-mb-px mr-1' : 'mr-1'"
        >
          <a
            style="height: 88px"
            :class="
              tab === activeTab
                ? 'bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-black-700 font-semibold'
                : 'bg-white inline-block py-2 px-4 text-black-100 hover:text-black-800 hover:font-semibold'
            "
            href="#"
            @click="changeActiveTab(tab)"
          >
            <div>
              {{ verboseTab(tab) }}
            </div>
            <span
              class="bg-indigo-100 text-indigo-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-indigo-900"
              v-text="
                tab === 'all'
                  ? fetchedImages.length
                  : fetchedImages.filter((i) => getStatus(i) === tab).length
              "
            ></span>
          </a>
        </li>
      </ul>
    </div>

    <Modal
      v-if="showModal"
      :onClose="closeModal"
      :callback="modalCallback"
      :title="modalTitle"
      :message="modalMessage"
    />

    <ModalAddAIModel
      v-if="showAddAiModal"
      :handleClickClose="
        () => {
          showAddAiModal = false;
        }
      "
      :handleAddModel="addModel"
    />

    <AIModelMenu
      v-if="showModelMenu"
      :onClose="
        () => {
          showModelMenu = false;
        }
      "
      :session="session"
      :selectedAIModels="selectedAIModels"
      :aiModels="aiModels"
      :addAIModel="selectAIModel"
      :removeAIModel="unselectAIModel"
      :handleSelectAIModel="handleSelectAIModel"
      :openAddAIModal="openAddAIModal"
    />

    <Validation
      v-if="showValidation"
      :id="imageId"
      :sessionToken="sessionToken"
      :session="session"
      :siteId="siteId"
      :siteAssessmentId="siteAssessmentId"
      :onClose="closeValidation"
      :getPrevious="getPrevious"
      :getNext="getNext"
      :aiModels="aiModels"
      :selectedAIModels="selectedAIModels"
      :selectedAIModel="selectedAIModel"
      :changeSelectedAIModel="changeSelectedAIModel"
      :selectAIModel="selectAIModel"
      :unselectAIModel="unselectAIModel"
      :userId="userId"
      :getStatus="getStatus"
      :clickPhoto="clickPhoto"
    />

    <div
      v-if="isLoading"
      class="fixed inset-0 flex flex-col items-center justify-center pointer-events-none"
      style="
        top: 0;
        z-index: 9999;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
        background: white;
      "
    >
      <svg
        class="animate-spin -ml-1 mr-3 h-12 w-12 text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>

      <h2
        class="mt-2 text-md font-medium tracking-tight text-gray-900"
        v-if="totalPhotos > 0"
      >
        {{ Number((fetchedImages.length / totalPhotos) * 100.0).toFixed(2) }}%
      </h2>
    </div>

    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      aria-live="assertive"
      class="fixed inset-0 flex items-end pointer-events-none sm:items-start"
      style="z-index: 9999"
    >
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="notification && notification.show"
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0"></div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    class="text-sm font-medium text-gray-900"
                    v-text="notification.title"
                  ></p>
                  <p
                    class="mt-1 text-sm text-gray-500"
                    v-text="notification.message"
                  ></p>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    @click="notification.show = false"
                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span class="sr-only">Close</span>
                    X
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div v-if="!isLoading && !shouldImportSite" id="gallery_container">
      <gallery
        :allImages="fetchedImages"
        :images="images"
        v-bind:session="session"
        :siteAssessmentId="siteAssessmentId"
        :sessionToken="sessionToken"
        :siteId="siteId"
        :clientSiteIdentifier="clientSiteIdentifier"
        :clickPhoto="clickPhoto"
        :onScroll="onScroll"
        :selectedAIModels="selectedAIModels"
        :getStatus="getStatus"
      ></gallery>
      <!-- <div class="my-24 flex items-center justify-end"> -->
      <div class="fixed bottom-2 right-2">
        <button
          @click="openModalRunModel()"
          type="button"
          class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
          style="margin: 10px; background-color: rgb(33, 43, 54)"
        >
          <PlayIcon class="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
          Run Recognition
        </button>
        <button
          @click="openSendToTrainModal()"
          type="button"
          class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
          style="margin: 10px; background-color: rgb(33, 43, 54)"
        >
          <PlayIcon class="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
          Send Validated Images To Train
        </button>
      </div>
    </div>

    <div
      v-if="shouldImportSite && !importStatus"
      class="container flex flex-col items-center justify-center"
      style="height: 100vh"
    >
      <h2 class="text-2xl font-extrabold tracking-tight text-gray-900">
        Do you want to load this site assessment?
      </h2>
      <button
        @click="importSite()"
        type="button"
        class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
        style="background-color: rgb(33, 43, 54)"
      >
        <PlayIcon class="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
        Import site
      </button>
    </div>
    <div
      v-if="shouldImportSite && importStatus"
      class="container flex flex-col items-center justify-center"
      style="height: 100vh"
    >
      <h2
        class="text-2xl font-extrabold tracking-tight text-gray-900 mb-10"
        v-text="importStatus"
      ></h2>
      <div class="ml-20 w-full bg-gray-200 rounded-full dark:bg-gray-700">
        <div
          class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
          :style="{ width: progress + '%' }"
        >
          {{ Number(progress).toFixed(2) }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlayIcon } from "@heroicons/vue/solid";
import CrossbarConnectionHeader from "../components/CrossbarConnectionHeader.vue";
import Gallery from "../components/Gallery.vue";
import Modal from "../components/Modal.vue";
import ModalAddAIModel from "../components/ModalAddAIModel.vue";
import AIModelMenu from "../components/AIModelMenu.vue";
import Validation from "./Validation";
import { processPhotoStatus, getColors } from "../utils";

// import mockedImageLoadAll from "../mock/crossbar/image.load_all.json";
const autobahn = require("autobahn");

const GALLERY_HEIGHT = window.innerHeight - 130;
const GALLERY_WIDTH = window.innerWidth;
let scrollThrottle = null;
let updateStatusThrottlePause = false;

export default {
  components: {
    PlayIcon,
    CrossbarConnectionHeader,
    Validation,
    Gallery,
    Modal,
    ModalAddAIModel,
    AIModelMenu,
  },

  data() {
    return {
      connected: false,
      session: null,
      userId: null,
      siteAssessmentStatus: null,
      images: [],
      imagesPerLoad: 10,
      fetchedImages: [],
      sessionToken: null,
      siteId: null,
      showModal: false,
      showAddAiModal: false,
      showModelMenu: false,
      siteAssessmentId: null,
      clientSiteIdentifier: null,
      API_URL: process.env.VUE_APP_API_DOTNET_CORE_URL,
      shouldImportSite: false,
      showValidation: false,
      imageId: null,
      importStatus: null,
      aiModels: [],
      selectedAIModels: [],
      selectedAIModel: null,
      notification: {
        show: false,
        title: "",
        message: "",
      },
      activeTab: "all", // options are: all, processing, processed, validating, validated
      tabs: [
        "all",
        "recognition_pending",
        "recognition_running",
        "recognition_finished",
        "validating",
        "validated",
        "training_pending",
        "training_running",
        "training_finished",
        "recognition_outdated",
      ],
      model: null,
      progress: 0.0,
      isLoading: true,
      totalPhotos: 0,
      photoStatusUpdates: [],
    };
  },
  methods: {
    handleScroll() {
      let initialImageIndex;
      let finalImageIndex;

      this.images.forEach((image, index) => {
        const imageElem = document.getElementById(image.id);
        const imageY = imageElem.getBoundingClientRect().y;

        if (imageY > 0) {
          if (imageY <= 150 && !initialImageIndex) {
            initialImageIndex = index;
          }
          if (imageY >= GALLERY_HEIGHT && !finalImageIndex) {
            finalImageIndex = index;
          }
        }
        if (initialImageIndex && finalImageIndex) return;
      });
      if (!finalImageIndex) finalImageIndex = this.images.length;

      initialImageIndex =
        finalImageIndex -
        this.getAmountImagesToLoad() -
        this.getAmountImagesToLoad() / 2;

      if (initialImageIndex < 0) initialImageIndex = 0;
      finalImageIndex += this.getAmountImagesToLoad() / 2;

      if (finalImageIndex > this.images.length)
        finalImageIndex = this.images.length;

      initialImageIndex = Math.ceil(initialImageIndex);
      finalImageIndex = Math.ceil(finalImageIndex);

      this.loadMoreImages(initialImageIndex, finalImageIndex);
    },
    throtteScroll() {
      clearTimeout(scrollThrottle);
      scrollThrottle = setTimeout(() => {
        this.handleScroll();
      }, 200);
    },
    onScroll() {
      this.throtteScroll();
    },
    handleUpdateAiModelPhotoStatus() {
      if (this.images !== undefined) {
        this.photoStatusUpdates.map(({ photoId, aiModelId, status }) => {
          this.images.map((photo) => {
            if (photo.id === photoId) {
              photo.ai_model_photos.map((aiModelPhoto) => {
                if (aiModelPhoto.ai_model_id === aiModelId) {
                  aiModelPhoto.status = status;
                }
              });
            }
          });

          this.fetchedImages.map((photo) => {
            if (photo.id === photoId) {
              photo.ai_model_photos.map((aiModelPhoto) => {
                if (aiModelPhoto.ai_model_id === aiModelId) {
                  aiModelPhoto.status = status;
                }
              });
            }
          });
        });

        this.photoStatusUpdates = [];

        this.loadInitialImages();
        this.throtteScroll();
      }
    },
    onUpdateAiModelPhotoStatus() {
      if(updateStatusThrottlePause) return;

      updateStatusThrottlePause = true;

      setTimeout(() => {
        this.handleUpdateAiModelPhotoStatus();
        updateStatusThrottlePause = false;
      }, 1000);
    },
    loadMoreImages(initialImageIndex, finalImageIndex) {
      const filteredImages = this.fetchedImages.filter((img) =>
        this.activeTab === "all" ? true : this.getStatus(img) === this.activeTab
      );

      const images = [
        ...this.images.slice(0, initialImageIndex),
        ...filteredImages.slice(initialImageIndex, finalImageIndex),
        ...this.images.slice(finalImageIndex),
      ];

      this.images = images;
    },
    openModalRunModel() {
      this.showModal = true;
      this.modalTitle = "Are you sure you want to proceed?";
      this.modalMessage = "";
      // this.modalMessage = "Running the model all images on status no recognition, training finished or recognition outdated will become recognition pending.";
      this.modalCallback = this.runModel;
    },
    openSendToTrainModal() {
      this.showModal = true;
      this.modalTitle = "Are you sure you want to proceed?";
      this.modalMessage = "";
      // this.modalMessage = "All the validated photos will become training pending.";
      this.modalCallback = this.sendToTrain;
    },
    onClickCurrentModels() {
      this.showModelMenu = true;
      this.loadAIModels();
    },
    closeModal() {
      this.showModal = false;
    },
    openAddAIModal() {
      this.showAddAiModal = true;
    },
    loadAIModels() {
      this.session
        .call("ai_model.get")
        .then((res) => {
          console.log("ai_model.get response: ", res);
          const aiModels = res;
          const colors = getColors(aiModels);
          this.aiModels = aiModels.map((model, i) => {
            const color = colors[i];
            model.color = color;
            return model;
          });
          this.loadSelectedAIModels();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addModel(data) {
      console.log("model: ", data);
      this.session
        .call("ai_model.add", [], {
          session_token: this.sessionToken,
          name: data.name,
          type: data.type,
          description: data.description,
        })
        .then((res) => {
          console.log("addModel response: ", res);
          if (!res.success) {
            this.notification = {
              show: true,
              title: "Error",
              message: res.message,
            };
            return;
          }
          this.loadAIModels();
        })
        .catch((err) => {
          console.log(err);
          this.notification = {
            show: true,
            title: "Error",
            message: "Error adding AI model ... Please, try again later.",
          };
        });
    },
    clickPhoto(image) {
      this.imageId = image.id;
      this.showValidation = true;
    },
    closeValidation() {
      this.showValidation = false;
    },
    getPrevious(id) {
      const el = this.fetchedImages.find((i) => i.id === id);
      const index = this.fetchedImages.indexOf(el);

      if (index - 1 >= 0) {
        return this.fetchedImages[index - 1];
      } else {
        const length = this.fetchedImages.length;
        return this.fetchedImages[length - 1];
      }
    },
    getNext(id) {
      const el = this.fetchedImages.find((i) => i.id === id);
      const index = this.fetchedImages.indexOf(el);

      const length = this.fetchedImages.length;

      if (index + 1 <= length - 1) {
        return this.fetchedImages[index + 1];
      } else {
        return this.fetchedImages[0];
      }
    },
    getAmountImagesToLoad() {
      const maxImageWidth = 440;
      const maxImageHeight = 360;
      const imagesGap = 40;
      const imagesWidth = GALLERY_WIDTH / (maxImageWidth + imagesGap);
      const imagesHeight = GALLERY_HEIGHT / (maxImageHeight + imagesGap);
      let imagesAmount = Math.ceil(imagesWidth * imagesHeight);
      imagesAmount = Math.ceil(imagesAmount + imagesAmount / 2);
      return imagesAmount;
    },
    loadInitialImages() {
      this.iamges = [];
      const initialImagesAmount = this.getAmountImagesToLoad() + 1;
      const filteredImages = this.fetchedImages.filter((img) =>
        this.activeTab === "all" ? true : this.getStatus(img) === this.activeTab
      );

      let loadURL = false;
      if (filteredImages.length < initialImagesAmount) loadURL = true;
      this.images = filteredImages.map((image) => ({
        ...image,
        thumbnail_url: loadURL ? image.thumbnail_url : null,
      }));

      if (!loadURL) {
        this.images = [
          ...filteredImages.slice(0, initialImagesAmount),
          ...this.images.slice(initialImagesAmount, this.images.length),
        ];
      }
    },
    loadPhotos(reloadImages = false) {
      this.session
        .call(
          "image.load_all",
          [],
          {
            site_assessment_id: this.siteAssessmentId,
          },
          { receive_progress: true }
        )
        .then(
          (total) => {
            console.log("total: ", total);

            if (reloadImages) {
              this.images = this.fetchedImages.slice(0, this.imagesPerLoad);
            }

            if (this.fetchedImages.length === 0) {
              this.shouldImportSite = true;
            }
            this.loadInitialImages();

            this.session
              .call(`site_assessment.${this.siteAssessmentId}.get_status`)
              .then((res) => {
                console.log("res .get_status: ", res);
                if (res && "status" in res) {
                  this.siteAssessmentStatus = res.status;
                }
              });

            this.isLoading = false;
          },
          (err) => {
            console.log(err);
            this.notification = {
              show: true,
              title: "Error",
              message: "Something went wrong ... Please, try again later.",
            };
          },
          (res) => {
            this.totalPhotos = res.total;
            console.log("progress: ", res.images.length);

            this.fetchedImages = this.fetchedImages.concat(res.images);
          }
        )
        .catch((err) => {
          console.log(err);
          this.notification = {
            show: true,
            title: "Error",
            message: "Something went wrong ... Please, try again later.",
          };
        });
    },
    handleSelectAIModel(model) {
      this.selectedAIModel = model;
    },
    loadUserId() {
      fetch(`${this.API_URL}/User/Profile`, {
        headers: {
          authorization: `Bearer ${this.sessionToken}`,
          "content-type": "application/json",
        },
        method: "GET",
      })
        .then((res) =>
          res.json().then((res) => {
            console.log("user id: ", res.Id);
            this.userId = res.Id;
          })
        )
        .catch((err) => {
          alert("Session token has expired.");
          console.log("err: ", err);
        });
    },
    sendToTrain() {
      this.session
        .call("ai_model.train", [], {
          site_assessment_id: this.siteAssessmentId,
          session_token: this.sessionToken,
        })
        .then((res) => {
          console.log("ai_model.train: ", res);
          if (!res.success) {
            this.notification = {
              show: true,
              title: "Send to Train error",
              message: res.message,
            };
          }
        })
        .catch((err) => {
          console.log(err);
          this.notification = {
            show: true,
            title: "Error",
            message: "Failed to train a new model.",
          };
        });
    },
    runModel() {
      this.session
        .call("ai_model.run", [], {
          site_assessment_id: this.siteAssessmentId,
        })
        .then((res) => {
          console.log("run_model response: ", res);
          this.isLoading = true;
          this.fetchedImages = [];
          this.loadPhotos(true);
        })
        .catch((err) => {
          console.log(err);
          this.notification = {
            show: true,
            title: "Error",
            message: "Error running the model.",
          };
        });
      this.notification = {
        show: true,
        title: "Model running",
        message: "Started running the model",
      };
    },
    importSite() {
      this.importStatus = "Loading ...";

      const firstInterval = setInterval(() => {
        if (this.progress < 33.3) {
          this.progress += 1;
        } else {
          this.progress = 33.3;
        }
      }, 100);

      this.session
        .call("site_assessment.import", [], {
          site_assessment_id: this.siteAssessmentId,
          site_id: this.siteId,
          site_identifier: this.clientSiteIdentifier,
          session_token: this.sessionToken,
        })
        .then((res) => {
          console.log("site_assessment.import response: ", res);
          if (!res.success) {
            this.notification = {
              show: true,
              title: "Error",
              message: res.message,
            };
            return;
          }
          this.importStatus = "Site assessment imported successfully.";

          clearInterval(firstInterval);

          const secondInterval = setInterval(() => {
            if (this.progress < 100.0) {
              this.progress += 1.66;
            } else {
              this.progress = 100.0;
              clearInterval(secondInterval);
            }
          }, 100);

          setTimeout(() => {
            this.loadPhotos();
            this.importStatus = "Loading photos ...";
            this.isLoading = true;
          }, 2000);

          setTimeout(() => {
            this.shouldImportSite = false;
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
          this.notification = {
            show: true,
            title: "Error",
            message:
              "Error importing the assessment ... Please, try again later.",
          };
        });
    },
    verboseTab(tab) {
      if (tab === "recognition_pending") {
        tab = "processing_pending";
      } else if (tab === "recognition_running") {
        tab = "processing";
      } else if (tab === "recognition_finished") {
        tab = "processing_finished";
      } else if (tab === "recognition_outdated") {
        tab = "processing_outdated";
      }

      return tab.charAt(0).toUpperCase() + tab.slice(1).replace("_", " ");
    },
    selectAIModel(id) {
      this.selectedAIModels = [...this.selectedAIModels, id];

      localStorage.setItem(
        "selectedAIModels",
        JSON.stringify(this.selectedAIModels)
      );
      this.updatePhotosStatus();
    },
    unselectAIModel(id) {
      this.selectedAIModels = this.selectedAIModels.filter((m) => m !== id);

      localStorage.setItem(
        "selectedAIModels",
        JSON.stringify(this.selectedAIModels)
      );
      this.updatePhotosStatus();
    },
    changeSelectedAIModel(model) {
      this.selectedAIModel = model;
    },
    getStatus(photo) {
      const aiModelPhotos = photo.ai_model_photos.filter((amp) =>
        this.selectedAIModels.includes(amp.ai_model_id)
      );

      const statuses = aiModelPhotos.map((amp) => amp.status);

      const status = processPhotoStatus(statuses);

      if (status) {
        return status.toLowerCase();
      }

      return "no_recognition";
    },
    updatePhotosStatus() {
      const processPhoto = (i) => {
        let aiModelPhotos;

        const n = this.selectedAIModels.filter(
          (mid) => !i.ai_model_photos.map((a) => a.ai_model_id).includes(mid)
        );
        if (n.length > 0) {
          i.status = "NO_RECOGNITION".toLowerCase();
          return i;
        }

        if (this.selectedAIModels.length > 0) {
          aiModelPhotos = i.ai_model_photos.filter((a) =>
            this.selectedAIModels.includes(a.ai_model_id)
          );
        } else {
          aiModelPhotos = i.ai_model_photos;
        }

        i.status = processPhotoStatus(
          aiModelPhotos.map((a) => a.status)
        ).toLowerCase();
        return i;
      };

      this.fetchedImages = this.fetchedImages.map((i) => processPhoto(i));
      this.images = this.images.map((i) => processPhoto(i));
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
      this.loadInitialImages();
    },
    loadSelectedAIModels() {
      const selectedAIModels = localStorage.getItem("selectedAIModels");

      if (selectedAIModels) {
        try {
          this.selectedAIModels = JSON.parse(selectedAIModels);
        } catch {
          this.selectedAIModels = this.aiModels
            .filter((m) => m.status !== "NOT_CREATED")
            .map((m) => m.id);
        }
      } else {
        this.selectedAIModels = this.aiModels
          .filter((m) => m.status !== "NOT_CREATED")
          .map((m) => m.id);
      }
    },
    loadSubscriptions() {
      console.log("this.siteAssessmentId: ", this.siteAssessmentId);

      // eslint-disable-next-line no-unused-vars
      this.session.subscribe(
        `site_assessment.${this.siteAssessmentId}.image.`,
        function () {
          const topic = arguments[2].topic;

          // eslint-disable-next-line no-unused-vars
          const [_siteAssessment, _siteAssessmentId, entity, photoId, aiModel, aiModelId, status] = topic.split('.');


          this.photoStatusUpdates.push({
            photoId,
            aiModelId,
            status,
          });

          this.onUpdateAiModelPhotoStatus();
        }.bind(this),
        { match: "prefix" }
      );

      this.session.subscribe(
        `ai_model.update_images_status`,
        function () {
          const data = arguments[1];

          console.log("data: ", data);

          data.message.images.forEach((i) => {
            this.images.map((img) => {
              if (i.id === img.id) {
                img.ai_model_photos.map((a) => {
                  if (a.ai_model_id === i.ai_model_id) {
                    a.status = i.status;
                  }
                });
              }
            });

            this.fetchedImages.map((img) => {
              if (i.id === img.id) {
                img.ai_model_photos.map((a) => {
                  if (a.ai_model_id === i.ai_model_id) {
                    a.status = i.status;
                  }
                });
              }
            });
          });
        }.bind(this)
      );

      this.session.subscribe(
        "ai_model.train_status",
        function (args) {
          console.log(
            "ai_model.train_status args: ",
            args,
            " - arguments: ",
            arguments
          );

          const data = arguments[1];

          this.notification = {
            show: true,
            title: "Training status",
            message: data.message,
          };

          this.loadAIModels();
        }.bind(this)
      );

      this.loadPhotos();

      this.loadAIModels();
    },
  },
  mounted() {
    document.addEventListener("imageUpdated", (e) => {
      const newImage = e.detail.value;

      this.images = this.images.map((image) => {
        if (image.id === newImage.id) {
          return newImage;
        }
        return image;
      });

      this.fetchedImages = this.fetchedImages.map((image) => {
        if (image.id === newImage.id) {
          return newImage;
        }
        return image;
      });
    });

    document.addEventListener("notification", (e) => {
      this.notification = {
        show: true,
        title: "title" in e.detail ? e.detail.title : "",
        message: "message" in e.detail ? e.detail.message : "",
      };
    });

    const params = new URLSearchParams(window.location.search);

    this.sessionToken = params.get("session_token") || null;

    if (!this.sessionToken) {
      alert("You must set session_token using query param");
    }

    this.siteAssessmentId = params.get("site_assessment_id") || null;

    if (!this.siteAssessmentId) {
      alert("You must set site_assessment_id query param");
    }

    this.siteId = params.get("site_id") || null;

    if (!this.siteId) {
      alert("You must set site_id query param");
    }

    this.clientSiteIdentifier = params.get("client_site_identifier") || null;

    if (!this.clientSiteIdentifier) {
      alert("You must set client_site_identifier query param");
    }

    this.loadUserId();

    if (process.env.VUE_APP_MOCK_CROSSBAR) {
      console.log("Mocking crossbar ...");

      this.session = {
        subscribe: () => {},
        call: () => {},
      };

      this.loadPhotos();

      return;
    }

    const connection = new autobahn.Connection({
      url: process.env.VUE_APP_CROSSBAR_SERVER_URL,
      realm: process.env.VUE_APP_CROSSBAR_REALM,
      authmethods: [process.env.VUE_APP_CROSSBAR_AUTH_METHODS],
      authid: process.env.VUE_APP_CROSSBAR_AUTH_ID,
      authrole: process.env.VUE_APP_CROSSBAR_ROLE,
      onchallenge: function () {
        return process.env.VUE_APP_CROSSBAR_CHALLENGE;
      },
    });

    connection.onopen = (session) => {
      this.connected = true;
      this.session = session;

      this.loadSubscriptions();
    };

    connection.onclose = () => {
      this.connected = false;
    };

    connection.open();
  },
};
</script>
