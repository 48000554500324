<template>
  <div class="bg-gray-200 py-2 px-8 flex items-center justify-between">
    <div class="flex items-center justify-center">
      <span class="p-2">
        <div
          :class="`rounded-full h-2 w-2 bg-${connected ? 'green' : 'red'}-600`"
        />
      </span>
      <span class="text-sm"> Crossbar backend connection. </span>
    </div>
    <div>
      <span class="text-sm mr-10" v-if="siteAssessmentStatus">Assessment Recognition Status: <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{{ siteAssessmentStatus }}</span></span>
      <!--
      <span class="text-sm">
        <button data-tooltip-target="tooltip-default" type="button" class="text-black focus:outline-none rounded-lg text-sm text-center" style="cursor: initial">
          <QuestionMarkCircleIcon 
            class="h-4 w-4"
            aria-hidden="true" style="display: inline-block"/>
        </button>
        Latest Model Version: 
        <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300" v-text=" model && 'version' in model ? model.version : 'AWS Rekognition default' "></span>
      </span>
      <span class="text-sm" v-if="model && 'f1_score' in model">Model Accuracy: <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300" v-text="Number(model.f1_score).toFixed(3)"></span></span>

      <div id="tooltip-default" role="tooltip" class="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
          The latest model version is the latest AI trained that is currently running.<br>The images will be processed by this model.
          <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
      -->

      <button type="button" class="
      inline-flex
      items-center
      px-3
      border border-transparent
      shadow-sm
      text-sm
      font-medium
      rounded-md
      text-white
      focus:outline-none focus:ring-2 focus:ring-offset-2
      " style="background-color: rgb(33, 43, 54)" @click="onClickCurrentModels()">{{ totalModelsCount > 0 ? totalModelsCount : '' }} Current models</button>

    </div>
  </div>
</template>
<script>
// import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";


export default {
  components: {
    // QuestionMarkCircleIcon,
  },
  props: {
    connected: Boolean,
    totalModelsCount: Number,
    siteAssessmentStatus: String,
    onClickCurrentModels: Function,
  },
};
</script>
<style>
<style > .bg-red-600 {
  background-color: rgba(220, 38, 38, 1);
}

.bg-green-600 {
  background-color: rgba(5, 150, 105, 1);
}
</style>
