<template>
    <button type="button" @click="onChangeToggle" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer" role="switch" aria-checked="checked">
    <span class="sr-only">Use setting</span>
    <span aria-hidden="true" class="pointer-events-none absolute bg-white w-full h-full rounded-md"></span>
    <span aria-hidden="true" :class=" (enabled ? 'bg-cci' : 'bg-gray-200' )  + ' pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'"></span>
    <span aria-hidden="true" :class=" (enabled ? 'translate-x-5' : 'translate-x-0') + ' pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'"></span>
    </button>
</template>

<script>
export default {
    props: {
        enabled: Boolean,
        modelId: String,
        changeValue: Function,
    },
    methods: {
        onChangeToggle() {
            this.changeValue(this.modelId);
        }
    },
}
</script>
