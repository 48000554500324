export function processPhotoStatus(statuses) {
    const precedenceStatuses = [
        "NO_RECOGNITION",
        "RECOGNITION_PENDING",
        "RECOGNITION_RUNNING",
        "RECOGNITION_FINISHED",
        "VALIDATING",
        "VALIDATED",
        "TRAINING_PENDING",
        "TRAINING_RUNNING",
        "TRAINING_FINISHED",
        "RECOGNITION_OUTDATED",
    ];

    return precedenceStatuses.find((s) => statuses.includes(s.toLowerCase()));
}

export function generateRandomColor() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
}

export function getColors(models) {
    const precendenceColors = [
        "rgb(127,255,127)", // light green
        "yellow",
        "blue",
        "brown",
        "orange",
        "pink",
        "red",
    ];

    let colors = [];

    for(let i = 0; i < models.length; i++) {
        if(i < precendenceColors.length) {
            colors.push(precendenceColors[i]);
        } else {
            colors.push(generateRandomColor());
        }
    }

    return colors;    
}

export function renameStatus(status) {
    return status.replace('recognition', 'processing');
}
