<template>
  <div
    class="relative flex flex-col bg-white shadow-xl"
    style="
      padding-left: 20px;
      height: 92vh;
      margin-bottom: 20px;
      width: 360px;
      z-index: 0;
    "
  >
    <div class="p-6">
      <div class="flex items-start justify-between">
        <h3 class="text-lg font-medium text-gray-900">Current models</h3>
        <div class="ml-3 flex h-7 items-center">
          <!--
                        <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="onClose">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      -->
        </div>
      </div>
    </div>
    <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-scroll">
      <li v-for="model in getLabelsGroupedByModels()" :key="model.id">
        <div
          class="group relative flex items-center py-6 px-5"
          :style="{
            border:
              selectedAIModel && selectedAIModel.id === model.id
                ? '3px solid rgb(33, 43, 54)'
                : 'none',
          }"
        >
          <div class="m-1 block flex-1">
            <div class="" aria-hidden="true" />
            <div class="relative flex min-w-0 flex-1 items-center">
              <div class="ml-4 truncate">
                <p
                  v-if="model && model.name"
                  @click="handleSelectAIModel(model)"
                  class="cursor-pointer truncate text-bg font-bold text-gray-900"
                >
                  {{ model.name }}
                  <span
                    v-if="'color' in model"
                    :class="['ml-1 w-3.5 h-3.5 mr-4']"
                    aria-hidden="true"
                    :style="{
                      backgroundColor: model.color,
                      display: 'inline-block',
                      border: '1px solid rgb(33, 43, 54)',
                    }"
                  />
                </p>
                <p
                  v-if="
                    'latest_version' in model &&
                    model.latest_version &&
                    model.latest_version.version
                  "
                  class="truncate text-sm text-gray-500"
                  style="
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 210px;
                    max-width: 100%;
                  "
                  :title="'Version: ' + model.latest_version.version"
                >
                  Version: {{ model.latest_version.version }}
                </p>
                <div class="flex items-center justify-between">
                  <p
                    v-if="
                      image &&
                      'ai_model_photos' in image &&
                      image.ai_model_photos.find(
                        (amp) => amp.ai_model_id === model.id
                      )
                    "
                    class="truncate mt-2 text-sm"
                  >
                    Status:
                    <span
                      :class="
                        'text-' +
                        getColorFromStatus(
                          image.ai_model_photos.find(
                            (amp) => amp.ai_model_id === model.id
                          ).status
                        ) +
                        '-800'
                      "
                      >{{
                        image.ai_model_photos
                          .find((amp) => amp.ai_model_id === model.id)
                          .status.replace("recognition", "processing")
                          .toUpperCase()
                      }}</span
                    >
                  </p>
                  <p v-else class="truncate mt-2 text-sm">
                    Status:
                    <span
                      :class="
                        'text-' + getColorFromStatus('no_recognition') + '-800'
                      "
                      >NO_PROCESSING</span
                    >
                  </p>
                </div>
                <p
                  class="flex items-center mt-2 cursor-pointer"
                  @click="onChangeToggle(model.id)"
                >
                  <span class="mr-1">Display:</span>
                  <EyeIcon
                    title="show model"
                    v-if="selectedAIModels.includes(model.id)"
                    class="h-4 w-4 cursor-pointer text-gray-800"
                    style="display: inline-block"
                    aria-hidden="true"
                  />
                  <EyeOffIcon
                    title="hide model"
                    v-else
                    class="h-4 w-4 cursor-pointer text-gray-500"
                    style="display: inline-block"
                    aria-hidden="true"
                  />
                </p>

                <p
                  v-if="'labels' in model && model.labels.length > 0"
                  class="mt-3 mb-1 text-gray-800"
                >
                  Labels:
                </p>
                <ul
                  role="list"
                  class="flex text-gray-500 divide-y divide-gray-200"
                  v-if="'labels' in model"
                  style="flex-direction: column"
                >
                  <li
                    class="py-4"
                    v-for="label in model.labels.sort((a, b) =>
                      a.localeCompare(b)
                    )"
                    :key="`${model.id}${label}`"
                  >
                    <div
                      class="flex items-center"
                      style="justify-content: space-between"
                    >
                      <span>
                        <span
                          class="inline-flex items-center justify-center px-2 py-0.5 rounded text-xs font-medium"
                          style="
                            text-align: center;
                            width: 30px;
                            background-color: rgba(33, 43, 54, 0.3);
                            color: rgba(33, 43, 54, 1);
                          "
                          >{{
                            entries.find(
                              (e) =>
                                e.ai_model_id === model.id && e.label === label
                            )
                              ? entries.filter(
                                  (e) =>
                                    e.ai_model_id === model.id &&
                                    e.label === label
                                ).length
                              : "0"
                          }}</span
                        ><span class="ml-2">{{ label }}</span>
                      </span>
                      <EyeIcon
                        @click="handleShow(model.id, label)"
                        title="show label"
                        v-if="showLabels.includes(`${model.id} ${label}`)"
                        class="h-4 w-4 cursor-pointer text-gray-800"
                        style="display: inline-block"
                        aria-hidden="true"
                      /><EyeOffIcon
                        @click="handleShow(model.id, label)"
                        title="hide label"
                        v-else
                        class="h-4 w-4 cursor-pointer text-gray-500"
                        style="display: inline-block"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                </ul>
                <li
                  v-if="'labels' in model && model.labels.length === 0"
                  class="text-gray-500 mt-2"
                >
                  No results found.
                </li>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  props: {
    onClose: Function,
    session: Object,
    selectedAIModels: Array,
    aiModels: Array,
    addAIModel: Function,
    removeAIModel: Function,
    image: Object,
    getStatus: Function,
    selectedAIModel: Object,
    handleSelectAIModel: Function,
    getColorFromStatus: Function,
    entries: Array,
    showLabels: Array,
    updateShowLabels: Function,
  },
  methods: {
    onChangeToggle(id) {
      if (this.selectedAIModels.includes(id)) {
        this.removeAIModel(id);
      } else {
        this.addAIModel(id);
      }
    },
    getLabelsGroupedByModels() {
      const sortedAIModels = [...this.aiModels];

      sortedAIModels.sort((a, b) => {
        if (a.is_trainable && !b.is_trainable) {
          return -1;
        }

        if (!a.is_trainable && b.is_trainable) {
          return 1;
        }

        return 0;
      });

      // const showKeys = [];

      const result = sortedAIModels.map((aiModel) => {
        const entries = this.entries.filter((e) => e.ai_model_id == aiModel.id);
        const distinctLabels = [...new Set(entries.map((b) => b.label))];

        // distinctLabels.map((l) => {
        //   showKeys.push(`${aiModel.id} ${l}`);
        // });

        return {
          ...aiModel,
          labels: distinctLabels,
        };
      });

      // if(!this.loaded) {
      //   this.updateShowLabels(showKeys);
      //   this.loaded = true;
      // }

      console.log("getLabelsGroupedByModels result: ", result);
      console.log("entries: ", this.entries);
      return result;
    },
    handleShow(aiModelId, name) {
      const key = `${aiModelId} ${name}`;
      if (this.showLabels.includes(key)) {
        this.updateShowLabels(this.showLabels.filter((k) => k !== key));
      } else {
        this.updateShowLabels([...this.showLabels, key]);
      }
    },
  },
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  data() {
    return {
      models: [],
      loaded: false,
    };
  },
};
</script>
