<template>
  <div
    style="
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 10;
      overflow-y: hidden;
      background: white;
    "
  >
    <div v-if="!notFound" class="flex flex-row">
      <ModalShortcuts
        v-if="showModalShortcuts"
        :shortcuts="shortcuts"
        :handleClickClose="
          () => {
            showModalShortcuts = false;
          }
        "
      />
      <!--
    <ModalAddNewLabel 
      v-if="showModalAddNewLabel"
      :handleClickClose="closeModalAddNewLabel"
      :handleAddLabel="handleAddLabel"
      :selectedAIModel="selectedAIModel"
      :aiModels="aiModels"
    />  
    -->
      <div>
        <div class="flex flex-row align-center justify-between mx-5 my-3">
          <div class="flex flex-row items-center">
            <ArrowLeftIcon
              class="h-6 w-6 mr-2 cursor-pointer"
              style="text-align: center"
              @click="backToInitialPage"
            />
            <h2
              class="text-2xl font-extrabold tracking-tight text-gray-900"
              style="display: inline-block"
              v-if="image && 'filename' in image"
              v-text="image.filename"
            ></h2>
            <span
              :class="`inline-flex items-center px-2 py-0.5 ml-2 rounded text-sm font-medium bg-${getColorFromStatus(
                getStatus(image)
              )}-100 text-${getColorFromStatus(getStatus(image))}-800`"
              v-if="image && 'ai_model_photos' in image"
            >
              {{
                getStatus(image)
                  .replace("_", " ")
                  .replace("recognition", "processing")
              }}
            </span>
          </div>
          <div class="flex flex-row">
            <span
              v-if="readOnlyMode && image && readOnlyMessage !== ''"
              class="inline-flex items-center px-2 py-0.5 ml-2"
              >{{ readOnlyMessage }}</span
            >
            <span
              :class="`inline-flex items-center px-2 py-0.5 ml-2 rounded text-sm font-medium bg-yellow-100 text-yellow-800`"
              v-if="readOnlyMode && image"
              >Read only</span
            >

            <InformationCircleIcon
              class="ml-3 -mr-1 h-8 w-8 cursor-pointer"
              aria-hidden="true"
              @click="
                () => {
                  showModalShortcuts = true;
                }
              "
            />
          </div>
        </div>

        <div class="flex items-center justify-center mb-10">
          <div id="bbox_annotator" style="display: inline-block"></div>
        </div>

        <div class="fixed bottom-2 left-2">
          <button
            @click="previousImage"
            type="button"
            class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            style="background-color: rgb(33, 43, 54); margin-right: 10px"
          >
            <ArrowLeftIcon class="h-6 w-6" />
          </button>
          <button
            @click="nextImage"
            type="button"
            class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            style="background-color: rgb(33, 43, 54); margin-right: 10px"
          >
            <ArrowRightIcon class="h-6 w-6" />
          </button>
        </div>

        <div class="fixed bottom-2 right-2" style="z-index: 10">
          <button
            @click="backToInitialPage"
            type="button"
            class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            style="background-color: rgb(33, 43, 54); margin-right: 10px"
          >
            Back
          </button>
          <!--
      <button
        v-if="!readOnlyMode"
        @click="openModalNewLabel"
        type="button"
        class="
          inline-flex
          items-center
          px-6
          py-3
          border border-transparent
          shadow-sm
          text-base
          font-medium
          rounded-md
          text-white
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
        "
        style="background-color: rgb(33, 43, 54); margin-right: 10px;"
      >
        Add new label
      </button>
      -->
          <button
            v-if="!readOnlyMode"
            @click="saveValidationAndReturnToMainPage"
            type="button"
            class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            style="
              background-color: rgb(33, 43, 54);
              margin-right: 10px;
              z-index: 999;
            "
          >
            Save
          </button>
        </div>
      </div>
      <AIModelMenuValidation
        v-if="true"
        :onClose="
          () => {
            showModelMenu = false;
          }
        "
        :session="session"
        :selectedAIModels="selectedAIModels"
        :aiModels="aiModels"
        :addAIModel="handleAddAIModel"
        :removeAIModel="handleRemoveAIModel"
        :image="image"
        :getStatus="getStatus"
        :entries="entries"
        :selectedAIModel="selectedAIModel"
        :handleSelectAIModel="handleSelectAIModel"
        :getColorFromStatus="getColorFromStatus"
        :updateShowLabels="updateShowLabels"
        :showLabels="showLabels"
      />
    </div>

    <div v-else>
      <div
        class="flex flex-col items-center justify-center"
        style="width: 100vw; height: 100vh"
      >
        <h1 class="text-3xl font-extrabold tracking-tight text-gray-1200">
          No photo to validate.
        </h1>
        <lottie-animation
          path="./gifs/empty-box.json"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          :width="256"
          :height="256"
        />

        <p class="text-base mt-2">
          We couldn't find a photo on validation stage.
        </p>
        <p class="text-base mt-5 mb-2">
          Would you like to back to initial page?
        </p>
        <button
          @click="backToInitialPage"
          type="button"
          class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { InformationCircleIcon } from "@heroicons/vue/solid";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/outline";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation";
// import ModalAddNewLabel from '../components/ModalAddNewLabel.vue';
import AIModelMenuValidation from "../components/AIModelMenuValidation.vue";
import ModalShortcuts from "../components/ModalShortcuts.vue";

export default {
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    InformationCircleIcon,
    LottieAnimation,
    // ModalAddNewLabel,
    ModalShortcuts,
    AIModelMenuValidation,
  },
  props: {
    id: String,
    siteId: String,
    siteAssessmentId: String,
    sessionToken: String,
    userId: String,
    session: Object,
    onClose: Function,
    getPrevious: Function,
    getNext: Function,
    aiModels: Array,
    selectedAIModels: Array,
    selectAIModel: Function,
    unselectAIModel: Function,
    selectedAIModel: Object,
    changeSelectedAIModel: Function,
    getStatus: Function,
    clickPhoto: Function,
  },
  data() {
    return {
      readOnlyMode: false,
      readOnlyMessage: "",
      readOnlyStatuses: [
        "recognition_finished",
        "validating",
        "validated",
        "training_finished",
        "recognition_outdated",
      ],
      showModalAddNewLabel: false,
      showModalShortcuts: false,
      annotator: null,
      notFound: false,
      clientSiteIdentifier: null,
      API_URL: process.env.VUE_APP_API_DOTNET_CORE_URL,
      image: {
        id: null,
        url: "",
        filename: "",
        description: "",
        status: "initial",
        timestamps: [],
        bounding_boxes: [],
      },
      labels: [],
      model: null,
      showModelMenu: false,
      siteAssessmentStatus: null,
      entries: [],
      showLabels: [],
      loaded: false,
      shortcuts: [
        {
          name: "select first ai model",
          command: "cntrl+shift+1",
        },
        {
          name: "select second ai model",
          command: "cntrl+shift+2",
        },
        {
          name: "save",
          command: "cntrl+shift+s",
        },
        {
          name: "back",
          command: "cntrl+shift+b",
        },
        // {
        //   name: 'open and close new label modal',
        //   command: 'cntrl+shift+l',
        // },
        {
          name: "next photo",
          command: "cntrl+shift+arrow right",
        },
        {
          name: "previous photo",
          command: "cntrl+shift+arrow left",
        },
        {
          name: "change visibility of select model",
          command: "cntrl+shift+space",
        },
      ],
    };
  },
  methods: {
    updateShowLabels(showLabels) {
      this.showLabels = showLabels;

      this.removeImageFrame();
      this.image = null;
      this.loadImage(this.id);
    },
    removeImageFrame() {
      const annotators = document.querySelectorAll(".image_frame");

      if (annotators.length >= 1) {
        annotators.forEach((element) => {
          element.remove();
        });

        console.log(`Removed ${annotators.length} image frame(s).`);
      }
    },
    backToInitialPage() {
      this.removeImageFrame();
      this.onClose();
    },
    getColorFromStatus(status) {
      if (
        ["selected", "recognition_pending", "recognition_running"].includes(
          status
        )
      ) {
        return "rose";
      }

      if (["validating", "recognition_finished"].includes(status)) {
        return "yellow";
      }

      if (status === "validated") {
        return "green";
      }
    },
    saveValidationAndReturnToMainPage(e) {
      console.log("saveValidationAndReturnToMainPage");
      e.preventDefault();

      // filter just custom models

      const selectedCustomModels = this.aiModels
        .filter(
          (aiModel) =>
            this.selectedAIModels.includes(aiModel.id) && aiModel.is_trainable
        )
        .map((aiModel) => aiModel.id);

      selectedCustomModels.map((aiModelId) => {
        this.session
          .call(`image.${this.image.id}.validate`, [], {
            session_token: this.sessionToken,
            ai_model_id: aiModelId,
          })
          .then((res) => {
            console.log("validate response: ", res);

            if (res.success) {
              this.session
                .call(`image.${this.id}.load`)
                .then((newImage) => {
                  this.image = newImage;

                  // Update grid
                  const myEvent = new CustomEvent("imageUpdated", {
                    detail: { value: newImage },
                  });
                  document.dispatchEvent(myEvent);

                  this.backToInitialPage();
                })
                .catch((err) => {
                  console.log(err);
                  const myEvent = new CustomEvent("notification", {
                    detail: {
                      title: "Error",
                      message: `Error saving bounding boxes.`,
                    },
                  });
                  document.dispatchEvent(myEvent);
                });
            } else {
              const myEvent = new CustomEvent("notification", {
                detail: { title: "Error", message: res.message },
              });
              document.dispatchEvent(myEvent);
            }
          })
          .catch((err) => {
            console.log(err);
            const myEvent = new CustomEvent("notification", {
              detail: {
                title: "Error",
                message: `Error saving bounding boxes.`,
              },
            });
            document.dispatchEvent(myEvent);
          });
      });
    },
    openModalNewLabel() {
      console.log("open model new label");
      this.showModalAddNewLabel = true;
    },
    closeModalAddNewLabel() {
      this.showModalAddNewLabel = false;
    },
    // ADD LABEL
    handleAddLabel(newLabel, aiModelId) {
      if (!this.readOnlyMode) {
        this.session
          .call("label.add", [], {
            session_token: this.sessionToken,
            name: newLabel,
            ai_model_id: aiModelId,
          })
          .then((res) => {
            console.log("label.add response: ", res);
            if (res.success) {
              const option = document.createElement("option");
              option.value = newLabel;
              option.text = newLabel;

              document.querySelector("select.label_input").appendChild(option);
            } else {
              const myEvent = new CustomEvent("notification", {
                detail: { title: "Error", message: res.message },
              });
              document.dispatchEvent(myEvent);
            }
          })
          .catch((err) => {
            console.log(err);
            const notificationEvent = new CustomEvent("notification", {
              detail: {
                title: "Error",
                message: "Error adding label. Please, try again later.",
              },
            });
            document.dispatchEvent(notificationEvent);
          });
      }
    },
    loadUserId() {
      fetch(`${this.API_URL}/User/Profile`, {
        headers: {
          authorization: `Bearer ${this.sessionToken}`,
          "content-type": "application/json",
          credentials: "include",
        },
        method: "GET",
      })
        .then((res) =>
          res.json().then((res) => {
            console.log("user id: ", res.Id);
          })
        )
        .catch((err) => {
          console.log("err:", err);
          alert("Session token has expired.");
        });
    },
    handleSelectAIModel(model) {
      console.log("handleSelectAIModel: ", model);
      this.changeSelectedAIModel(model);

      this.removeImageFrame();
      this.image = null;
      this.loadImage(this.id);

      // TODO - just need to delete and recreate the labels, don't need to reload the entire image.
    },
    savePhoto(bounding_box, ai_model_id, action) {
      this.session
        .call(`image.${this.image.id}.save`, [], {
          bounding_box: bounding_box,
          ai_model_id: ai_model_id,
          session_token: this.sessionToken,
          action: action,
        })
        .then((res) => {
          console.log("image.save response:", res);

          if (!res.success) {
            const myEvent = new CustomEvent("notification", {
              detail: { title: "Error", message: res.message },
            });
            document.dispatchEvent(myEvent);
          }

          this.session.call(`image.${this.image.id}.load`).then((newImage) => {
            this.image = newImage;

            const myEvent = new CustomEvent("imageUpdated", {
              detail: { value: newImage },
            });
            document.dispatchEvent(myEvent);
          });
        })
        .catch((err) => {
          console.log(err);

          const myEvent = new CustomEvent("notification", {
            detail: {
              title: "Error",
              message: `Error saving bounding boxes. Refresh the page or try again later.`,
            },
          });
          document.dispatchEvent(myEvent);
        });
    },
    loadImage(imageId = null) {
      const id = imageId || this.id;

      this.session
        .call(`image.${id}.load`)
        .then((res) => {
          console.log("image: ", res);
          this.image = res;

          if (!this.loaded) {
            let showKeys = [];
            if ("ai_model_photos" in res) {
              res.ai_model_photos.map((amp) => {
                if (amp.bounding_boxes) {
                  amp.bounding_boxes.map((bb) => {
                    showKeys.push(`${amp.ai_model_id} ${bb.label}`);
                  });
                }
              });

              this.showLabels = showKeys;

              this.loaded = true;
            }
          }

          let entries = [];

          res.ai_model_photos.map((ai_model_photo) => {
            const aiModel = this.aiModels.find(
              (a) => a.id === ai_model_photo.ai_model_id
            );
            let color = "gray";
            if (aiModel) {
              color = aiModel.color;
            }

            if (ai_model_photo.bounding_boxes) {
              const bounding_boxes = ai_model_photo.bounding_boxes.map((b) => {
                b.ai_model_id = ai_model_photo.ai_model_id;

                const key = `${b.ai_model_id} ${b.label}`;

                if (!this.selectedAIModels.includes(b.ai_model_id)) {
                  b.show = false;
                } else {
                  b.show = this.showLabels
                    ? this.showLabels.includes(key)
                    : true;
                }

                b.color = color;

                if (b.user_type === "SYSTEM") {
                  b.border_style = "dashed";
                } else {
                  b.border_style = "solid";
                }

                b.unclosable = !aiModel.is_trainable;
                return b;
              });

              bounding_boxes.map((b) => {
                entries.push(b);
              });
            }
          });

          console.log("entries: ", entries);

          const checkIfIsReadOnly = () => {
            if (this.selectedAIModel && !this.selectedAIModel.is_trainable) {
              return {
                isReadOnly: true,
                message: `The selected AI model "${this.selectedAIModel.name}" is non trainable.`,
              };
            }

            let aiModelPhoto;
            if (this.image && "ai_model_photos" in this.image) {
              aiModelPhoto = this.image.ai_model_photos.find(
                (amp) => amp.ai_model_id === this.selectedAIModel.id
              );
            }

            if (!aiModelPhoto) {
              return {
                isReadOnly: true,
                message: "Photo with status NO_PROCESSING is read only.",
              };
            }

            if (
              aiModelPhoto &&
              this.readOnlyStatuses.includes(aiModelPhoto.status)
            ) {
              return {
                isReadOnly: false,
                message: "",
              };
            } else {
              return {
                isReadOnly: true,
                message:
                  "Photo with status " + aiModelPhoto.status + " is read only.",
              };
            }
          };

          const { isReadOnly, message } = checkIfIsReadOnly();
          this.readOnlyMode = isReadOnly;
          this.readOnlyMessage = message;

          let labels = [];

          res.ai_model_photos.map((ai_model_photo) => {
            if (
              this.selectedAIModel &&
              this.selectedAIModel.id !== ai_model_photo.ai_model_id
            ) {
              return;
            }

            ai_model_photo.labels.map((l) => {
              labels.push(l.name);
            });
          });

          this.removeImageFrame();

          function getMeta(url, callback) {
            var img = new Image();
            img.src = url;
            img.onload = function () {
              callback(this.width, this.height);
            };
          }

          function removeScaleEntry(entry, scale) {
            entry.top = entry.top / scale.width;
            entry.left = entry.left / scale.height;
            entry.width = entry.width / scale.width;
            entry.height = entry.height / scale.height;

            return entry;
          }

          getMeta(this.image.thumbnail_url, (width, height) => {
            const aspectRatio = width / height;
            const newWidth = aspectRatio * 800;

            const pH = 800 / height;
            const pW = newWidth / width;
            const scale = {
              width: pW,
              height: pH,
            };

            // eslint-disable-next-line no-undef
            this.annotator = new BBoxAnnotator({
              url: this.image.thumbnail_url,
              input_method: "text", // Can be one of ['text', 'select', 'fixed']
              labels: labels.sort((a, b) => a.localeCompare(b)),
              width: newWidth,
              height: 800,
              user_id: this.userId,
              border_color: this.selectedAIModel
                ? this.selectedAIModel.color
                : "rgb(127,255,127)",
              guide: true,
              readonly_mode: this.readOnlyMode,
              // min_confidence: Math.min.apply(null, confidences),
              // max_confidence: Math.max.apply(null, confidences),
              oninvalidinput: function() {
                const myEvent = new CustomEvent("notification", {
                  detail: {
                    title: "Invalid label",
                    message: `Select one of the label options.`,
                  },
                });
                document.dispatchEvent(myEvent);
              }.bind(this),
              onadd: function (newEntry) {
                const entry = {
                  ...newEntry,
                  ai_model_id: this.selectedAIModel.id,
                };

                console.log(
                  "entry added: ",
                  entry,
                  "this.selectedAIModel.id: ",
                  this.selectedAIModel.id
                );

                this.entries.push(entry);

                const k = `${entry.ai_model_id} ${entry.label}`;

                if (!this.showLabels.includes(k)) {
                  this.showLabels = [...this.showLabels, k];
                }

                this.session.publish(`image.${this.image.id}.add_bbox`, [
                  entry,
                ]);

                const saveEntry = { ...entry };

                const removeAttrs = [
                  "border_style",
                  "color",
                  "unclosable",
                  "ai_model_id",
                  "show",
                ];

                removeAttrs.map((attr) => {
                  delete saveEntry[attr];
                });

                this.savePhoto(
                  removeScaleEntry(saveEntry, scale),
                  this.selectedAIModel.id,
                  "add"
                );

                return entry;
              }.bind(this),
              ondelete: function (deletedEntry) {
                console.log("deletedEntry: ", deletedEntry);
                const aiModelId = deletedEntry.ai_model_id;

                const saveEntry = { ...deletedEntry };
                const removeAttrs = [
                  "border_style",
                  "color",
                  "unclosable",
                  "ai_model_id",
                  "show",
                ];
                removeAttrs.map((attr) => delete saveEntry[attr]);

                const newEntries = this.entries.filter(
                  (e) =>
                    !(
                      e.height === deletedEntry.height &&
                      e.width === deletedEntry.width &&
                      e.left === deletedEntry.left &&
                      e.top === deletedEntry.top &&
                      e.label === deletedEntry.label
                    )
                );
                this.entries = newEntries;

                this.session.publish(`image.${this.image.id}.remove_bbox`, [
                  deletedEntry,
                ]);

                this.savePhoto(
                  removeScaleEntry(saveEntry, scale),
                  aiModelId,
                  "remove"
                );
              }.bind(this),
            });

            entries.map((entry) => {
              (entry.top = entry.top * scale.width),
                (entry.left = entry.left * scale.height),
                (entry.width = entry.width * scale.width),
                (entry.height = entry.height * scale.height),
                this.annotator.add_entry(entry);
            });

            this.entries = entries;

            if (this.readOnlyMode) {
              document.querySelector(".image_frame").style.cursor =
                "not-allowed";
            }
          });
        })
        .catch((err) => {
          console.log(err);
          const myEvent = new CustomEvent("notification", {
            detail: {
              title: "Error",
              message: `Error loading image. Please, try again later.`,
            },
          });
          document.dispatchEvent(myEvent);
        });
    },
    handleAddAIModel(modelId) {
      this.selectAIModel(modelId);

      this.removeImageFrame();
      this.image = null;
      this.loadImage(this.id);
      // TODO - just need to reload the bounding boxes from BBoxAnnotator, don't need to reload the entire image
    },
    handleRemoveAIModel(modelId) {
      this.unselectAIModel(modelId);

      this.removeImageFrame();
      this.image = null;
      this.loadImage(this.id);

      // TODO - just need to filter the bounding boxes from BBoxAnnotator, don't need to reload the entire image
    },
    previousImage() {
      const previous = this.getPrevious(this.image.id);
      this.removeImageFrame();
      this.image = null;
      this.loaded = false;

      // this.id = previous.id;
      this.clickPhoto(previous);
      this.loadImage(previous.id);
    },
    nextImage() {
      const next = this.getNext(this.image.id);
      this.removeImageFrame();
      this.image = null;
      this.loaded = false;

      // this.id = next.id;
      this.clickPhoto(next);
      this.loadImage(next.id);
    },
    processEntry(entry, aiModel) {
      let color = "gray";
      if (aiModel) {
        color = aiModel.color;
      }

      entry.color = color;

      if (entry.user_type === "SYSTEM") {
        entry.border_style = "dashed";
      } else {
        entry.border_style = "solid";
      }

      entry.unclosable = !aiModel.is_trainable;

      return entry;
    },
    shortcutsListener(event) {
      const shortcut = String.fromCharCode(event.keyCode).toLowerCase();
      console.log(event.keyCode);

      for (let i = 0; i < 10; i++) {
        if (event.ctrlKey && event.shiftKey && shortcut === Number(i + 1).toString()) {
          if (this.aiModels.length > i) {
            this.handleSelectAIModel(this.aiModels[i]);
          }
        }
      }

      if (event.ctrlKey && event.shiftKey && shortcut === "s") {
        this.saveValidationAndReturnToMainPage(event);
      }

      if (event.ctrlKey && event.shiftKey && shortcut === "b") {
        this.backToInitialPage();
      }
      // if(event.ctrlKey && shortcut === 'l') {
      //   if(!this.showModalAddNewLabel) {
      //     this.openModalNewLabel();
      //   } else {
      //     this.closeModalAddNewLabel();
      //   }
      // }
      // if (event.keyCode === 27 && this.showModalAddNewLabel) {
      //   //esc
      //   this.closeModalAddNewLabel();
      // }
      if (event.ctrlKey && event.shiftKey && event.keyCode === 39) {
        //arrow right
        this.nextImage();
      }
      if (event.ctrlKey && event.shiftKey && event.keyCode === 37) {
        //arrow right
        this.previousImage();
      }

      if (event.ctrlKey && event.shiftKey && event.keyCode === 32) {
        //space
        if (this.selectedAIModels.includes(this.selectedAIModel.id)) {
          this.handleRemoveAIModel(this.selectedAIModel.id);
        } else {
          this.handleAddAIModel(this.selectedAIModel.id);
        }
      }
    },
  },
  mounted() {
    console.log("mounted Validation");
    this.removeImageFrame();

    window.addEventListener("keydown", this.shortcutsListener);

    try {
      if (!this.image || !this.image.id) {
        this.loadImage();
      }

      this.session.subscribe(`image.${this.id}.add_bbox`, (entries) => {
        let entry = entries[0];

        const k = `${entry.ai_model_id} ${entry.label}`;

        if (!this.showLabels.includes(k)) {
          this.showLabels = [...this.showLabels, k];
        }

        const aiModel = this.aiModels.find((a) => a.id === entry.ai_model_id);

        entry = this.processEntry(entry, aiModel);

        this.annotator.add_entry(entry);

        this.entries = this.annotator.entries;
      });

      this.session.subscribe(`image.${this.id}.remove_bbox`, (entries) => {
        let entry = entries[0];

        const newEntries = this.annotator.entries.filter(
          (e) =>
            !(
              e.height === entry.height &&
              e.width === entry.width &&
              e.left === entry.left &&
              e.top === entry.top &&
              e.label === entry.label
            )
        );

        this.annotator.clear_all();

        newEntries.map((entry) => {
          this.annotator.add_entry(entry);
        });

        this.entries = newEntries;
      });
    } catch (err) {
      // alert("Error on Crossbar RPC.");
      this.notFound = true;
      console.error(err);
      return;
    }

    this.session
      .call("ai_model.get")
      .then((res) => {
        console.log("ai_model.get response: ", res);
        this.model = res && "current_model" in res ? res.current_model : null;
      })
      .catch((err) => {
        console.log(err);

        const myEvent = new CustomEvent("notification", {
          detail: {
            title: "Training status",
            message: `Error getting model version.`,
          },
        });
        document.dispatchEvent(myEvent);
      });

    this.session
      .call(`site_assessment.${this.siteAssessmentId}.get_status`)
      .then((res) => {
        console.log("res .get_status: ", res);
        if (res && "status" in res) {
          this.siteAssessmentStatus = res.status;
        }
      });
    if (!this.selectedAIModel && this.aiModels.length > 0) {
      const sortedAIModels = [...this.aiModels];

      sortedAIModels.sort((a, b) => {
        if (a.is_trainable && !b.is_trainable) {
          return -1;
        }

        if (!a.is_trainable && b.is_trainable) {
          return 1;
        }

        return 0;
      });
      this.changeSelectedAIModel(sortedAIModels[0]);
    }
  },
  unmounted() {
    console.log("Unmount Validation component");
    this.removeImageFrame();

    window.removeEventListener("keydown", this.shortcutsListener);
  },
};
</script>
<style>
.bg-rose-100 {
  background-color: rgb(255, 228, 230);
}

.text-rose-800 {
  color: rgb(159, 18, 57);
}

.bg-yellow-100 {
  background-color: rgb(254, 249, 195);
}

.text-yellow-800 {
  color: rgb(133, 77, 14);
}

.bg-green-100 {
  background-color: rgb(220, 252, 231);
}

.text-green-800 {
  color: rgb(22, 101, 52);
}
</style>
