<template>
  <Select id="select-page" />
</template>

<script>

import Select from "./pages/Select.vue";

export default {
  components: {
    Select,
  },
  name: 'App',
}
</script>

<style>
body {
  font-family: Nunito, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8f8;
}

#image-wrapper {
  height: 640px;
  width: 640px;
  background-repeat: no-repeat;
  position: relative;
}
</style>
